import React, { useEffect } from "react"

export default function Home() {
  // useEffect(() => {
  //   window &&
  //     window.location.replace(
  //       "https://www.arthaus.co.uk/insights/insights.html"
  //     )
  // }, [])
  return (
    <div>
      <h1>Visit <a href='https://www.arthaus.co.uk/insights/insights.html'>Arthaus Insights page</a></h1>
    </div>
  )
}
